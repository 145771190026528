<template>
  <svg viewBox="0 0 21 21">
    <g clip-path="url(#clip0)">
      <path
        d="M5.28538 18.0006H1.13895V6.31699H5.28538V18.0006ZM3.20993 4.72324C1.88404 4.72324 0.808594 3.7623 0.808594 2.60215C0.808594 2.04488 1.06159 1.51044 1.51193 1.1164C1.96227 0.722349 2.57306 0.500977 3.20993 0.500977C3.84681 0.500977 4.4576 0.722349 4.90794 1.1164C5.35827 1.51044 5.61127 2.04488 5.61127 2.60215C5.61127 3.7623 4.53538 4.72324 3.20993 4.72324ZM20.8041 18.0006H16.6666V12.3131C16.6666 10.9576 16.6354 9.21934 14.5108 9.21934C12.355 9.21934 12.0247 10.692 12.0247 12.2154V18.0006H7.8827V6.31699H11.8595V7.91074H11.9175C12.4711 6.99277 13.8233 6.02402 15.8407 6.02402C20.0372 6.02402 20.8086 8.44199 20.8086 11.5826V18.0006H20.8041Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0.808594 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "LinkedIcon",
};
</script>
